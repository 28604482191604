import React from 'react';

type reportType = {
  BukkenNo: string,
  Kojimei: string,
  SyusekiNo: string,
  Iro: string,
  KumiKahan: string,
  Tonyubi: string,
  LastChecker: string,
  LastCheckedDate: string,
  Last1stAuthorizer: string,
  Last1stAuthorizedDate: string,
  Last2ndAuthorizer: string,
  Last2ndAuthorizedDate: string,
  Last3rdAuthorizedDate: string,
  Last3rdAuthorizer: string,
  Detail: reportDetailType[],
}
type reportDetailType = {
  id: number,
  Madoban: string,
  KumitateUnit: string,
  Setsu: string,
  Kanryosu: string,
  QualityChecker: string,
  Kamikotei: string,
  GKns01: string, GKns02: string, GKns03: string, GKns04: string, GKns05: string, GKns06: string, GKns07: string, GKns08: string, GKns09: string, GKns10: string,
  KKns01: string, KKns02: string, KKns03: string, KKns04: string, KKns05: string, KKns06: string, KKns07: string,
  SKns01: string, SKns02: string, SKns03: string, SKns04: string, SKns05: string, SKns06: string, SKns07: string, SKns08: string, SKns09: string, SKns10: string,
  Yojo: string, Hantei: string, Biko: string
}

interface Props {
  reportData: any[]
}
interface State { }

function reportRows(rows: any[]) {
  let reportData: reportType[] = []
  let Detail: reportDetailType[] = []

  let preBukkenNo = rows.length === 0 ? '' : rows[0].BukkenNo
  let preKojimei = rows.length === 0 ? '' : rows[0].Kojimei
  let preSyusekiNo = rows.length === 0 ? '' : rows[0].SyusekiNo
  let preIro = rows.length === 0 ? '' : rows[0].Iro
  let preKumiKahan = rows.length === 0 ? '' : rows[0].KumiKahan
  let preTonyubi = rows.length === 0 ? '' : rows[0].Tonyubi
  let LastChecker = ''
  let LastCheckedDate = ''
  let Last1stAuthorizer = ''
  let Last1stAuthorizedDate = ''
  let all1stAuthorizedFlag = 0
  let Last2ndAuthorizer = ''
  let Last2ndAuthorizedDate = ''
  let all2ndAuthorizedFlag = 0
  let Last3rdAuthorizer = ''
  let Last3rdAuthorizedDate = ''
  let all3rdAuthorizedFlag = 0

  rows.map((row, index) => {
    // 帳票ページブレイクキー(物件番号+集積番号)したことを判定しデータをpushする
    if (!(preBukkenNo === row.BukkenNo &&
      preSyusekiNo === row.SyusekiNo)) {
      reportData.push({
        "BukkenNo": preBukkenNo,
        "Kojimei": preKojimei,
        "SyusekiNo": preSyusekiNo,
        "Iro": preIro,
        "KumiKahan": preKumiKahan,
        "Tonyubi": preTonyubi,
        "LastChecker": LastChecker,
        "LastCheckedDate": LastCheckedDate,
        "Last1stAuthorizer": all1stAuthorizedFlag === 1 ? '' : Last1stAuthorizer,
        "Last1stAuthorizedDate": all1stAuthorizedFlag === 1 ? '' : Last1stAuthorizedDate,
        "Last2ndAuthorizer": all2ndAuthorizedFlag === 1 ? '' : Last2ndAuthorizer,
        "Last2ndAuthorizedDate": all2ndAuthorizedFlag === 1 ? '' : Last2ndAuthorizedDate,
        "Last3rdAuthorizer": all3rdAuthorizedFlag === 1 ? '' : Last3rdAuthorizer,
        "Last3rdAuthorizedDate": all3rdAuthorizedFlag === 1 ? '' : Last3rdAuthorizedDate,
        "Detail": Detail,
      })
      //明細(窓番、組立ユニット)を初期化する
      Detail = []
      LastCheckedDate = ""
      Last1stAuthorizedDate = ""
      all1stAuthorizedFlag = 0
      Last2ndAuthorizedDate = ""
      all2ndAuthorizedFlag = 0
      Last3rdAuthorizedDate = ""
      all3rdAuthorizedFlag = 0
    }

    Detail.push({
      "id": row.id,
      "Madoban": row.Madoban,
      "KumitateUnit": row.KumitateUnit,
      "Setsu": row.Setsu,
      "Kanryosu": row.Kanryosu,
      "QualityChecker": row.QualityChecker_ja,
      "Kamikotei": row.Kamikotei,
      "GKns01": row.GKns01, "GKns02": row.GKns02, "GKns03": row.GKns03, "GKns04": row.GKns04, "GKns05": row.GKns05, "GKns06": row.GKns06, "GKns07": row.GKns07, "GKns08": row.GKns08, "GKns09": row.GKns09, "GKns10": row.GKns10,
      "KKns01": row.KKns01, "KKns02": row.KKns02, "KKns03": row.KKns03, "KKns04": row.KKns04, "KKns05": row.KKns05, "KKns06": row.KKns06, "KKns07": row.KKns07,
      "SKns01": row.SKns01, "SKns02": row.SKns02, "SKns03": row.SKns03, "SKns04": row.SKns04, "SKns05": row.SKns05, "SKns06": row.SKns06, "SKns07": row.SKns07, "SKns08": row.SKns08, "SKns09": row.SKns09, "SKns10": row.SKns10,
      "Yojo": row.Yojo, "Hantei": row.Hantei, "Biko": row.Biko
    })

    // 最終検査員 を取得
    if (!LastCheckedDate || (LastCheckedDate < row.QuaritycheckDate)) {
      LastChecker = row.QualityChecker_ja
      LastCheckedDate = row.CheckedDate
    }
    // 最終第一承認者 を取得
    if (!row.FirstAuthorizedDate) {
      all1stAuthorizedFlag = 1
    }
    if (!Last1stAuthorizedDate || (Last1stAuthorizedDate < row.FirstAuthorizedDate)) {
      Last1stAuthorizer = row.FirstAuthorizer_ja
      Last1stAuthorizedDate = row.FirstAuthorizedDate
    }

    // 最終第二承認者 を取得
    if (!row.SecondAuthorizedDate) {
      all2ndAuthorizedFlag = 1
    }
    if (!Last2ndAuthorizedDate || (Last2ndAuthorizedDate < row.SecondAuthorizedDate)) {
      Last2ndAuthorizer = row.SecondAuthorizer_ja
      Last2ndAuthorizedDate = row.SecondAuthorizedDate
    }

    // 最終品質承認者 を取得
    if (!row.ThirdAuthorizedDate) {
      all3rdAuthorizedFlag = 1
    }
    if (!Last3rdAuthorizedDate || (Last3rdAuthorizedDate < row.ThirdAuthorizedDate)) {
      Last3rdAuthorizer = row.ThirdAuthorizer_ja
      Last3rdAuthorizedDate = row.ThirdAuthorizedDate
    }

    // 最後にレコードを追加する
    if (rows.length === index + 1) {
      reportData.push({
        "BukkenNo": row.BukkenNo,
        "Kojimei": row.Kojimei,
        "SyusekiNo": row.SyusekiNo,
        "Iro": row.Iro,
        "KumiKahan": row.KumiKahan,
        "Tonyubi": row.Tonyubi,
        "LastChecker": LastChecker,
        "LastCheckedDate": LastCheckedDate,
        "Last1stAuthorizer": all1stAuthorizedFlag === 1 ? '' : Last1stAuthorizer,
        "Last1stAuthorizedDate": all1stAuthorizedFlag === 1 ? '' : Last1stAuthorizedDate,
        "Last2ndAuthorizer": all2ndAuthorizedFlag === 1 ? '' : Last2ndAuthorizer,
        "Last2ndAuthorizedDate": all2ndAuthorizedFlag === 1 ? '' : Last2ndAuthorizedDate,
        "Last3rdAuthorizer": all3rdAuthorizedFlag === 1 ? '' : Last3rdAuthorizer,
        "Last3rdAuthorizedDate": all3rdAuthorizedFlag === 1 ? '' : Last3rdAuthorizedDate,
        "Detail": Detail,
      })
    }
    preBukkenNo = row.BukkenNo
    preKojimei = row.Kojimei
    preSyusekiNo = row.SyusekiNo
    preIro = row.Iro
    preKumiKahan = row.KumiKahan
    preTonyubi = row.Tonyubi
  })
  return (
    reportData
  )
};
export default class ReportBa01R01 extends React.Component<Props, State> {
  public render(): JSX.Element {
    const reportdatax = reportRows(this.props.reportData)
    return (
      <div className='report_page'>
        {reportdatax.map((summary: reportType) =>
          <div className='pdf_page'>
            <table >
              <table className='page_head_table'>
                <tr >
                  <td >
                    <table >
                      <tr >
                        <td >
                          物件番号：{summary.BukkenNo}&emsp;物件名：{summary.Kojimei}
                        </td>
                      </tr >
                      <tr >
                        <td>
                          集積番号：{summary.SyusekiNo}&emsp;
                          組立課：{summary.KumiKahan}&emsp;
                          色指示：{summary.Iro}&emsp;
                        </td>
                      </tr >
                      <tr >
                        <td >
                          投入日：{summary.Tonyubi}&emsp;
                          検査日：{summary.LastCheckedDate}
                        </td>
                      </tr >
                    </table>
                  </td>
                  <td className='report_title'>
                    組立作業指示書(報告書)
                  </td >
                  <td align="left" className='page_header'>
                    <table >
                      <tr>
                        <th className='block_hanko1'>品質検査員</th>
                        <th className='block_hanko1'>課長</th>
                        <th className='block_hanko1'>最終検査員</th>
                      </tr>
                      <tr>
                        <th className='block_hanko2'><br />{summary.Last3rdAuthorizedDate}<br />{summary.Last3rdAuthorizer} </th>
                        <th className='block_hanko2'><br />{summary.Last2ndAuthorizedDate}<br />{summary.Last2ndAuthorizer}</th>
                        <th className='block_hanko2'><br />{summary.Last1stAuthorizedDate}<br />{summary.Last1stAuthorizer} </th>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>

              <table >
                <thead className='thead'>
                  <tr className='table_header' >
                    <th className='block_th1a'></th>
                    <th className='block_th1a'></th>
                    <th className='block_th1a'></th>
                    <th className='block_th1a'></th>
                    <th className='block_th1a'>担当者</th>
                    <th className='block_th1a'></th>
                    <th className='block_th1a' colSpan={10}>寸法検査</th>
                    <th className='block_th1a' colSpan={10}>外観検査</th>
                    <th className='block_th1a' colSpan={7}>機能検査</th>
                    <th className='block_th1a'></th>
                    <th className='block_th1a'></th>
                    <th className='block_th1b'></th>
                  </tr>
                  <tr className='table_header' >
                    <th className='block_th2a'><span>窓番</span></th>
                    <th className='block_th2a'><span>組立ユニット</span></th>
                    <th className='block_th2a'><span>数量</span></th>
                    <th className='block_th2a'><span>完了数</span></th>
                    <th className='block_th2b'><span>組立者</span></th>

                    <th className='block_th2a'><span>上工程品質確認</span></th>

                    <th className='block_th2b'><span>幅上（枠の内のり）</span></th>
                    <th className='block_th2b'><span>幅下（枠の内のり）</span></th>
                    <th className='block_th2b'><span>高さ左（枠の内のり）</span></th>
                    <th className='block_th2b'><span>高さ右（枠の内のり）</span></th>
                    <th className='block_th2b'><span>寸法の差(枠対辺内のり)</span></th>
                    <th className='block_th2b'><span>枠見込み</span></th>
                    <th className='block_th2b'><span>取付位置（無目・中骨）</span></th>
                    <th className='block_th2b'><span>隙間寸法幅（障子と枠）</span></th>
                    <th className='block_th2b'><span>隙間寸法高さ(障子と枠)</span></th>
                    <th className='block_th2b'><span>取付位置（施錠部品）</span></th>

                    <th className='block_th2b'><span>色調</span></th>
                    <th className='block_th2b'><span>カエリ（キズ）</span></th>
                    <th className='block_th2b'><span>隙間（目違い）</span></th>
                    <th className='block_th2b'><span>取付状態（補助補強材）</span></th>
                    <th className='block_th2b'><span>かみ合せ（シーラー）</span></th>
                    <th className='block_th2b'><span>状態（シーリング）</span></th>
                    <th className='block_th2b'><span>裏打ち状態</span></th>
                    <th className='block_th2b'><span>防火戸証紙</span></th>
                    <th className='block_th2b'><span>ＢＬ証紙</span></th>
                    <th className='block_th2b'><span>その他ラベル</span></th>

                    <th className='block_th2b'><span>施錠開閉状態</span></th>
                    <th className='block_th2b'><span>勝手確認</span></th>
                    <th className='block_th2b'><span>タイト状態</span></th>
                    <th className='block_th2b'><span>かかりしろ（建具状態）</span></th>
                    <th className='block_th2b'><span>機能状態（金具・部品）</span></th>
                    <th className='block_th2b'><span>確認（水抜状態）</span></th>
                    <th className='block_th2b'><span>角度・斜辺（曲げ）</span></th>

                    <th className='block_th2a'><span>養生</span></th>
                    <th className='block_th2a'><span>判定</span></th>
                    <th className='block_th2syochi'><span>処置</span></th>
                  </tr>
                </thead>
                <tbody className='table_body'>
                  {summary.Detail.map((ditails: reportDetailType) => (
                    <tr className='table_rows'>
                      <td className='block_td1a'>{ditails.Madoban}</td >
                      <td className='block_td1b'>{ditails.KumitateUnit}</td >
                      <td className='block_td1c'>{ditails.Setsu}</td >
                      <td className='block_td1c'>{ditails.Kanryosu}</td >
                      <td className='block_td2kumitatesya'>{ditails.QualityChecker}</td >
                      <td className='block_td1b'>{ditails.Kamikotei}</td >

                      <td className='block_td1c'>{ditails.SKns01}</td >
                      <td className='block_td1c'>{ditails.SKns02}</td >
                      <td className='block_td1c'>{ditails.SKns03}</td >
                      <td className='block_td1c'>{ditails.SKns04}</td >
                      <td className='block_td1c'>{ditails.SKns05}</td >
                      <td className='block_td1c'>{ditails.SKns06}</td >
                      <td className='block_td1c'>{ditails.SKns07}</td >
                      <td className='block_td1c'>{ditails.SKns08}</td >
                      <td className='block_td1c'>{ditails.SKns09}</td >
                      <td className='block_td1c'>{ditails.SKns10}</td >

                      <td className='block_td1b'>{ditails.GKns01}</td >
                      <td className='block_td1b'>{ditails.GKns02}</td >
                      <td className='block_td1b'>{ditails.GKns03}</td >
                      <td className='block_td1b'>{ditails.GKns04}</td >
                      <td className='block_td1b'>{ditails.GKns05}</td >
                      <td className='block_td1b'>{ditails.GKns06}</td >
                      <td className='block_td1b'>{ditails.GKns07}</td >
                      <td className='block_td1b'>{ditails.GKns08}</td >
                      <td className='block_td1b'>{ditails.GKns09}</td >
                      <td className='block_td1b'>{ditails.GKns10}</td >

                      <td className='block_td1b'>{ditails.KKns01}</td >
                      <td className='block_td1b'>{ditails.KKns02}</td >
                      <td className='block_td1b'>{ditails.KKns03}</td >
                      <td className='block_td1b'>{ditails.KKns04}</td >
                      <td className='block_td1b'>{ditails.KKns05}</td >
                      <td className='block_td1b'>{ditails.KKns06}</td >
                      <td className='block_td1b'>{ditails.KKns07}</td >

                      <td className='block_td1b'>{ditails.Yojo}</td >
                      <td className='block_td1b'>{ditails.Hantei}</td >
                      <td className='block_td2syochi'>{ditails.Biko}</td >
                    </tr>
                  ))}
                </tbody>
              </table>
            </table>
          </div>
        )
        }
        <style type="text/css" media="print">
          {`
            @page { 
              size: A4 landscape ;
              margin-top: 1cm; 
              margin-bottom: 1cm;
              margin-left: 1cm; 
              margin-right: 1cm;
              font-size: 11pt;
            }
             .report_page {
               zoom: 70%;
             }
            .pdf_page {
                size: A4;
                position: relative;
                font-family: MS Gothic;
                page-break-after: always;
              }
            .pdf_page:after{
                position: absolute;
                right: 0;
                bottom: 0;
            }
            table {
              margin: 0px auto 0px;
              width: 100%;
              border-spacing:0;
            }
            thead {
              display: table-header-group;
              break-inside: avoid;
              border: 1px solid;
              counter-increment: page_count;
            }
            .pdf_page:last-child{
                page-break-after: auto;
            }
            .page_header {
              font-size: 16px;
            }
            .table_header {
              background-color: #c0c0c0;
              padding-left: 20px;
              padding-right: 20px;
            }
            .table_rows {
              padding-left: 20px;
              padding-right: 20px;
            }
            .table_body tr:nth-child(even) {
              background: #eee;
            }
            .page_head_table {
              height: 0px;
            }
            .report_title {
              font-size: 32px;
            }
            .block_hanko1 {
              display: table-cell;
              border-left: 1px solid;
              border-right: 1px solid;
              border-top: 1px solid;
              border-bottom: 1px solid;
              width: 90px;
              height: 10px;
              text-align: center;
              vertical-align: middle;
            }
            .block_hanko2 {
              display: table-cell;
              border-left: 1px solid;
              border-right: 1px solid;
              border-top: 1px solid;
              border-bottom: 1px solid;
              width: 90px;
              height: 90px;
              text-align: center;
              vertical-align: top;
            }
            .block_th1a {
              display: table-cell;
              border-left: 1px solid;
              border-top: 1px solid;
              width: 20px;
              text-align: center;
            }
            .block_th1b {
              display: table-cell;
              border-right: 1px solid;
              border-left: 1px solid;
              border-top: 1px solid;
              width: 20px;
            }
            .block_th2madoban {
              display: table-cell;
              border-left: 1px solid;
              border-bottom: 1px solid;
              width: 50px;
              height: 190px;
              padding-top: 10px;
              -ms-writing-mode: tb-rl;
              writing-mode: vertical-rl;
              text-align: center;
              vertical-align: top;
            }
            .block_th2a {
              display: table-cell;
              border-left: 1px solid;
              border-bottom: 1px solid;
              width: 20px;
              height: 190px;
              padding-top: 10px;
              -ms-writing-mode: tb-rl;
              writing-mode: vertical-rl;
              text-align: center;
            }
            .block_th2a span {
              display: inline-block;
              height: 190px;
              text-align: left;
              }
            .block_th2b {
              display: table-cell;
              border-left: 1px solid;
              border-top: 1px solid;
              border-bottom: 1px solid;
              width: 10px;
              height: 190px;
              padding-top: 10px;
              -ms-writing-mode: tb-rl;
              writing-mode: vertical-rl;
              text-align: center;
            }
            .block_th2b span {
              display: inline-block;
              height: 190px;
              text-align: left;
            }
            .block_th2syochi {
              display: table-cell;
              border-left: 1px solid;
              border-right: 1px solid;
              border-bottom: 1px solid;
              writing-mode: vertical-rl;
              width: 160px;
              height: 190px;
              -ms-writing-mode: tb-rl;
              writing-mode: vertical-rl;
              padding-top: 10px;
              text-align: center;
            }
            .block_th2syochi span {
              display: inline-block;
              height: 190px;
              text-align: left;
            }
            .block_td1a {
              display: table-cell;
              border-left: 1px solid;
              border-bottom: 1px solid;
              width: 84px;
              text-align: left;
              padding-top: 10px;
            }
            .block_td2kumitatesya {
              display: table-cell;
              border-left: 1px solid;
              border-bottom: 1px solid;
              width: 100px;
              text-align: left;
              padding-top: 10px;
              word-wrap: break-word;
            }
            .block_td1b {
              display: table-cell;
              border-left: 1px solid;
              border-bottom: 1px solid;
              width: 19px;
              text-align: center;
              padding-top: 10px;
            }
            .block_td1c {
              display: table-cell;
              border-left: 1px solid;
              border-bottom: 1px solid;
              width: 15px;
              text-align: right;
              padding-top: 10px;
            }
            .block_td2syochi {
              display: table-cell;
              border-left: 1px solid;
              border-right: 1px solid;
              border-bottom: 1px solid;
              writing-mode: vertical-rl
              width: 150px;
              margin: 3px;
              text-align: left;
              padding-top: 10px;
            }
            .box-content{
              width:-moz-fit-content; /* Firefox */
              width:fit-content; /* other browsers */
            }
          `}
        </style>
      </div >
      );
  }
}
